/* eslint-disable*/
import React, { useEffect, useState } from "react";
// import ReactGA from "react-ga";

import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";

import get from "lodash.get";
import { ampRabbitMQBtnEvent, ampRabbitMQPageEvent } from "../../../utils";
import { language, checkUserRecentOrder } from "../../../utils/services";
import { plengTestUserIds } from "../../../../constants";

export const PlengPayment = (props) => {
  //  GA code
  // const TRACKING_ID = `${process.env.REACT_APP_GA_CODE_PWA}`; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.pageview(window.location.pathname);
  // const useAnalyticsEventTracker = (category="Payment Init") => {
  //     const eventTracker = (action = "action init", label = "label init") => {
  //       ReactGA.event({category, action, label});
  //     }
  //     return eventTracker;
  //   }
  //   const gaEventTracker = useAnalyticsEventTracker('Coupon Code');

  /* Hooks */
  const history = useHistory();
  const pathname = useLocation().pathname;
  const location = useLocation().search;
  const hostname = window.location.host;

  /* Params */
  const couponcode = new URLSearchParams(location).get("couponcode")
    ? new URLSearchParams(location).get("couponcode")
    : "";
  const plan_id = new URLSearchParams(location).get("plan_id");
  const product_id = new URLSearchParams(location).get("product_id");
  const platform_id = new URLSearchParams(location).get("platform_id");
  const country = new URLSearchParams(location).get("country");
  const plan_type = new URLSearchParams(location).get("plan_type");
  const identity = new URLSearchParams(location).get("identity");
  const content_id = new URLSearchParams(location).get("content_id");
  const uL = new URLSearchParams(location).get("upilist");
  const lan = new URLSearchParams(location).get("lang");
  const npay_redirect = new URLSearchParams(location).get("npay_redirect");
  const npay_campaignsource = new URLSearchParams(location).get(
    "npay_campaignsource"
  );
  const campaignsource = new URLSearchParams(location).get("campaignsource");
  const npay_affilaite = new URLSearchParams(location).get("npay_affilaite");
  const aff_id = new URLSearchParams(location).get("aff_id");
  const utm_source = new URLSearchParams(location).get("utm_source");
  const originalSource = new URLSearchParams(location).get("source");
  const urlparams = location.replace("?", "");
  const is_smart_pay_number = new URLSearchParams(location).get(
    "is_smart_pay_number"
  );
  const isPlengLiveEvent = new URLSearchParams(location).get(
    "isPlengLiveEvent"
  );

  /* States */
  const [plan, setPlan] = useState({ discountPrice: " " });
  const [featured, setFeatured] = useState({});
  const [payments, setPayments] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [total_user_coins, setTotal_user_coins] = useState([]);
  const [netBankingPayments, setNetBankingPayments] = useState([]);
  const [upiPayments, setUpiPayments] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isLoadingVerify, setisLoadingVerify] = useState(false);
  const [textData, setTextData] = useState({});
  const [pushbar, setPushbar] = useState({
    openCoupon: false,
    Verifybottom1: false,
    Verifybottom2: false,
    verifyRedeemCoins: false,
    verifyAmazonPay: false,
    isTimer: false,
    invalidSmartNumber: false,
    abaPopup: false,
  });
  const [formData, setFormData] = useState({
    couponcode: couponcode,
    couponVerified: false,
    couponError: false,
    cardHolderName: "",
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    upiid: "",
  });
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [abaCardIframeLink, setAbaCardIframeLink] = useState("");
  const [abaCardPopUpParams, setAbaCardPopUpParams] = useState({});
  // const [tabHideShow,setTabHideShow]=useState({
  //     upiTab:false,
  //     ccTab:false,
  // })

  /* Functions */

  const goToPreviousPath = (contentId, planName) => {
    if (
      platform_id === "1" &&
      plan_type?.substring(0, 2).toLowerCase() === "le"
    ) {
      // window.location.href = `${process.env.REACT_APP_HANGAMA_URL}/wvclose.php`;
      window.location.href = `https://hungama.com/payment/success/`;
    }
    history.goBack();
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
  };

  const handleCoupenClick = (e) => {
    // amplitudeBtnEvent(identity, "Btn_Open_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Open_Coupon"
    );
    const name = e.target.getAttribute("data-name");
    let value = e.target.getAttribute("value");
    setPushbar((prev) => {
      // console.log(value);
      return { ...prev, [name]: value === "true" ? true : false }; //value === "true" ? true : false
    });
  };

  const handleClearCoupon = () => {
    // setFormData((prev) => { return { ...prev, couponcode: "" } });
    setFormData((prev) => {
      return {
        ...prev,
        couponcode: "",
        couponError: false,
      };
    });
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      {
        identity: identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_Remove_Coupon"
    );
  };

  const cancelVerifyCoupon = (e) => {
    if (e) e.preventDefault();
    setFormData((prev) => {
      return { ...prev, couponcode: "", couponVerified: false };
    });
    setPushbar((prev) => {
      return { ...prev, openCoupon: false };
    });
    setPlan((prev) => {
      return { ...prev, discountPrice: undefined };
    });
    setIsCouponValid(false);
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_Remove_Coupon"
    );
  };

  const handleCardPayments = (payment_id, plan_details_id) => {
    let url;
    if (payment_id == 22) {
      url = `${
        process.env.REACT_APP_HANGAMA_URL
      }/billing/smartpay/SmartpayBilling.php?payment_id=${payment_id}&identity=${identity}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&lang=${lan}&coupon_code=${
        formData?.couponcode ? formData?.couponcode : ""
      }`;
    } else {
      url = `${
        process.env.REACT_APP_HANGAMA_URL
      }/billing/pay.php?payment_id=${payment_id}&identity=${identity}&plan_details_id=${plan_details_id}&product_id=${product_id}&type=charge&${urlparams}&lang=${lan}&coupon_code=${
        formData?.couponcode ? formData?.couponcode : ""
      }`;
    }
    console.log(
      "payment url::",
      url,
      "payment id",
      payment_id,
      "plan details",
      plan_details_id
    );
    window.location.href = url;
  };

  // if (couponcode === null) formData.couponVerified = false;
  // if (couponcode && couponcode.trim() === '') formData.couponVerified = false;
  // if (couponcode === undefined) formData.couponVerified = false;

  const verifyCoupon = (e) => {
    // amplitudeBtnEvent(identity, "Btn_Submit_Coupon", product_id, platform_id);
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_Submit_Coupon"
    );
    // gaEventTracker('apply','HUNGAMA_'+`${formData.couponcode}`);
    // gaEventTracker('apply',`HUNGAMA_${formData.couponcode}`);
    if (e) e.preventDefault();
    const url = `${process.env.REACT_APP_HANGAMA_URL}/coupons/get_discounted_value.php?coupon_code=${formData.couponcode}&${urlparams}`;
    axios
      .get(url)
      .then((res) => {
        setisLoadingVerify(false);
        const couponDiscount = get(res, "data.coupon_details.value", "");
        const coupontype = get(res, "data.coupon_details.type", "");
        const couponStatus = get(res, "data.status", "");

        if (couponStatus === 1) {
          setIsCouponValid(true);
        }

        if (couponDiscount !== "" || null || undefined) {
          let discount;
          if (coupontype === "discounted") {
            discount =
              plan?.plan_price - (couponDiscount / 100) * plan?.plan_price;
          } else if (coupontype === "flat") {
            discount = plan?.plan_price - couponDiscount;
          }
          console.log(discount);

          setFormData((prev) => {
            return { ...prev, couponVerified: true, couponError: false };
          });
          setPushbar((prev) => {
            return { ...prev, openCoupon: false };
          });
          setPlan((prev) => {
            return { ...prev, discountPrice: discount };
          });
        } else {
          setFormData((prev) => {
            return { ...prev, couponVerified: false, couponError: true };
          });
          // setPushbar((prev) => {
          //     return { ...prev, openCoupon: false }
          // });
          setPlan((prev) => {
            return { ...prev, discountPrice: undefined };
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /* checkRecentOrder Function and Redirect to Payment Success/Fail according to status */
  const checkRecentOrder = async () => {
    //params from abapopup
    if (Object.keys(abaCardPopUpParams).length !== 0) {
      // let { identity, product_id, plan_details_id, payment_id } =
      //   abaCardPopUpParams;
      //check recent order in delay and redirect as per status

      let { status, data } = await checkUserRecentOrder(abaCardPopUpParams);
      if (status === "success" && data?.order_data?.order_status == 1) {
        window.location.href = `/pleng/payment_success?identity=${data?.order_data?.identity}&order_id=${data?.order_data?.order_id}&lang=${lan}&product_id=${data?.order_data?.product_id}&platform_id=${data?.order_data?.platform_id}`;
      } else if (status === "success" && data?.order_data?.order_status == 5) {
        window.location.href = `/pleng/payment_fail?identity=${data?.order_data?.identity}&order_id=${data?.order_data?.order_id}&lang=${lan}&product_id=${data?.order_data?.product_id}&platform_id=${data?.order_data?.platform_id}`;
      } else {
        checkRecentOrder();
      }
    }
  };

  // console.log(couponcode);
  if (isLoadingVerify) {
    if (couponcode !== "") {
      verifyCoupon();
    }
  }

  useEffect(() => {
    if (isLoading) {
      language(lan, product_id).then((res) => {
        // setDefaultTextData(res?.default);
        setTextData(res?.secondLang ? res["secondLang"] : res["default"]);
      });

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        "api-key": process.env.REACT_APP_API_KEY,
      };

      const body = {
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        plan_id: plan_id,
        plan_type: plan_type,
        content_id: content_id,
        identity: identity,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/billing/payments`,
        headers: headers,
        data: body,
      })
        .then((res) => {
          const featuredData = res?.data?.featuredPayment;
          const planData = res?.data?.planInfo;
          const paymentsdata = res?.data?.payments;
          const walletPaymentsdata = res?.data?.walletPayments;
          const upiPaymentsdata = res?.data?.upiPayments;
          const netBankingPaymentsdata = res?.data?.netBankingPayments;
          // console.log(paymentsdata);
          // console.log("payments.total_user_coins");
          // console.log();
          //var item_value = sessionStorage.getItem("plan_name_val");

          // Assign value to a key
          sessionStorage.setItem(
            "plan_name_val",
            planData.plan_name + "_" + planData.plan_price
          );
          setTotal_user_coins(res?.data?.total_user_coins);
          setFeatured(featuredData);
          setPlan(planData);
          setPayments(paymentsdata);
          setWallets(walletPaymentsdata);
          setNetBankingPayments(netBankingPaymentsdata);
          setUpiPayments(upiPaymentsdata);
          setLoading(false);
          setisLoadingVerify(true);

          /* amplitude code start*/
          let pageType = "PG Page_Subscription";
          // if (plan_type === "subscription" || plan_type === "event") {
          //   pageType = "PG Page_Subscription";
          //   source = "Plan Page";
          // } else if (plan_type === "live_concert") {
          //   pageType = "PG Page_Live Event";
          // } else {
          //   pageType = "PG Page_TVOD";
          //   source = "Movie";
          // }

          ampRabbitMQPageEvent({
            identity: identity,
            "Page Type": pageType,
            Source: "Plan Page",
            "Original Source": originalSource ? originalSource : "N/A",
            "Plan Name": planData?.plan_name,
            "Plan Validity": planData?.plan_valid,
            "Plan Value": planData?.plan_price?.toFixed(2),
            "Plan Currency": planData?.plan_currency,
            "Plan ID": plan_id,
            "Page Language": lan ? lan : "en",
            product_id: product_id,
            platform_id: platform_id,
          });
          /* amplitude code end*/

          //Handle anndroid Google Wallet Payment for Live Event
          if (
            platform_id === "1" &&
            isPlengLiveEvent === "1" &&
            paymentsdata.length != 0
          ) {
            let { payment_id, plan_details_id } = paymentsdata?.find(
              (el) => el?.payment_id === 11
            );
            // alert("handleAndroidGoogleWalletLiveEvent..!!");
            handleAndroidGoogleWalletLiveEvent(
              identity,
              plan_details_id,
              product_id,
              urlparams,
              couponcode,
              payment_id
            );
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
      const logData = {
        url: `${hostname}${pathname}`,
        params: location,
        couponcode: couponcode,
        plan_id: plan_id,
        product_id: product_id,
        platform_id: platform_id,
        country: country,
        identity: identity,
        content_id: content_id,
        plan_type: plan_type,
        upiList: uL,
      };

      const data = JSON.stringify(logData);
      const body1 = {
        logData: data,
      };

      axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/v1/frontendlogs`,
        headers: headers,
        data: body1,
      })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });

      // var requestOptions = {
      //     method: 'GET',
      //     redirect: 'follow'
      // };

      // fetch("https://ipapi.co/json/", requestOptions)
      //     .then(response => response.text())
      //     .then((res) => {
      //         // const result = JSON.parse(res);
      //         // // return '';
      //         // let campaignsourceval, affilaite;
      //         // if (!npay_campaignsource) { campaignsourceval = campaignsource; }
      //         // if (!npay_affilaite) { affilaite = aff_id; }

      //         // /* Rabbit MQ */
      //         // const rabbitMQData = {
      //         //     'identity': identity,
      //         //     'product_id': product_id,
      //         //     'auth-key': process.env.REACT_APP_MQ_AUTH_KEY,
      //         //     'event_type': 'npay_page_paymentoptions',
      //         //     'event_time': Math.floor(new Date().getTime() / 1000),
      //         //     'queue_name': process.env.REACT_APP_QUEUE_NAME,
      //         //     'ip': result.ip,
      //         //     'country': result.country,
      //         //     'event_properties': {
      //         //         'npay_redirect': 'false',
      //         //         'campaignsource': campaignsourceval,
      //         //         'affilaite': affilaite,
      //         //     }
      //         // }

      //         // console.log(rabbitMQData);

      //         // axios({
      //         //     method: "POST",
      //         //     url: `${process.env.REACT_APP_API_URL}/v1/notify/mq/receiver`,
      //         //     headers: headers,
      //         //     data: rabbitMQData
      //         // })
      //         //     .then((res) => {
      //         //         // console.log('rabbitmq')
      //         //         // console.log(res);
      //         //     })
      //         //     .catch((err) => {
      //         //         console.log(err);
      //         //     });

      //     })
      //     .catch(error => console.log('error', error));
      if (is_smart_pay_number && is_smart_pay_number == "0") {
        setPushbar((prev) => {
          return { ...prev, invalidSmartNumber: true };
        });
      }
    }
  }, [
    utm_source,
    originalSource,
    aff_id,
    npay_redirect,
    aff_id,
    campaignsource,
    npay_affilaite,
    npay_campaignsource,
    pushbar.openCoupon,
    country,
    couponcode,
    hostname,
    identity,
    location,
    pathname,
    uL,
    isLoading,
    plan_id,
    urlparams,
    content_id,
    plan_type,
    platform_id,
    product_id,
    lan,
    is_smart_pay_number,
    isPlengLiveEvent,
  ]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 300); // 10 minutes timer

  // console.log(upiPayments);
  useEffect(() => {
    if (
      pushbar.openCoupon ||
      pushbar.Verifybottom1 ||
      pushbar.Verifybottom2 ||
      pushbar.verifyRedeemCoins ||
      pushbar.verifyAmazonPay ||
      pushbar.invalidSmartNumber ||
      pushbar.abaPopup
    ) {
      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed"; //ios
      document.body.style.height = "100vh"; //ios
      document.body.style.width = "100%"; //ios
    } else if (!pushbar.openCoupon) {
      document.body.style.overflow = "visible";
      document.body.style.removeProperty("position"); //ios
      document.body.style.removeProperty("height"); //ios
      document.body.style.removeProperty("width"); //ios
    }
  }, [pushbar]);

  const handleAndroidGoogleWalletLiveEvent = (
    p_identity,
    p_plan_details_id,
    p_product_id,
    p_urlparams,
    p_coupon_code,
    p_payment_id
  ) => {
    // amplitudeBtnEvent(identity, "Btn_GoogleWallet", product_id, platform_id);
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_GoogleWallet"
    );
    // gaEventTracker('google_wallet',p_plan_details_id) ;
    let url;
    url = `${process.env.REACT_APP_HANGAMA_URL}/billing/pay.php?payment_id=${p_payment_id}&identity=${p_identity}&plan_details_id=${p_plan_details_id}&product_id=${p_product_id}&type=charge&${p_urlparams}&coupon_code=${p_coupon_code}`;
    // console.log("payment url::", url);
    window.location.href = url;
  };

  return (
    <>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <div
            className="wrapper"
            style={{
              pointerEvents:
                pushbar.openCoupon ||
                pushbar.Verifybottom1 ||
                pushbar.Verifybottom2 ||
                pushbar.verifyRedeemCoins ||
                pushbar.verifyAmazonPay ||
                pushbar.invalidSmartNumber ||
                pushbar.abaPopup
                  ? "none"
                  : "auto",
            }}
          >
            <div className="main-container">
              <div className="pay-head">
                {platform_id === "1" ? (
                  <div
                    onClick={() =>
                      goToPreviousPath(content_id, plan?.plan_name)
                    }
                    className="pay-back"
                  >
                    <img
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/back-arrow.svg"
                      }
                      alt="arrow"
                    />
                  </div>
                ) : (
                  ""
                )}
                {/* <span>Payment</span> */}
                {platform_id === "1" ? (
                  <span>{textData?.pay_payment_heading}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="plan-box">
                <div className="plan-box-top">
                  <div className="plan-box-inner flex2">
                    <div className="plan-poster-box">
                      {/* <img src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/12-months.svg"} class="12-months-img"/> */}
                      {plan?.plan_image !== "" && (
                        <img
                          src={plan?.plan_image}
                          alt="poster"
                          className="plan-posterimg"
                        />
                      )}
                    </div>
                    <div className="plan-content-box">
                      {/* <div class="plan-dname">SUBSCRIPTION</div>
						<h3 class="plan-name">Twelve Months Plan</h3>
						<p class="plan-des">Unlock Pleng Pro, No Ads and Limits, Unlimited Downloads</p> */}
                      <div className="plan-dname">
                        {plan?.plan_dname !== "" && plan?.plan_dname}
                      </div>
                      <h3 className="plan-name">
                        {plan?.plan_name !== "" && plan?.plan_name}
                      </h3>
                      {/* <p className="plan-des">
                        {plan?.plan_des !== "" && plan?.plan_des}
                      </p> */}
                      <p className="plan-des">
                        {plan?.plan_dname === "live event"
                          ? plan?.event_date
                          : plan?.plan_des}
                        {plan?.plan_dname === "live event" && (
                          <span className="event-time">
                            {plan?.event_time !== "" && plan?.event_time}
                          </span>
                        )}
                      </p>
                      <p className="plan-valid">
                        {plan?.plan_valid !== "" && plan?.plan_valid}
                      </p>
                    </div>
                    <div className="plan-price-box">
                      {/* <p class="plan-price">{plan?.plan_currency_symbol}{plan?.plan_price}</p> */}
                      {plan?.discountPrice ? (
                        <p className="plan-price ruppetxt">
                          <span className="rwpee-icon">
                            &#x20B9;{plan.plan_price}
                          </span>
                          &#x20B9;{plan.discountPrice}
                        </p>
                      ) : (
                        <p className="plan-price">
                          {plan?.plan_currency_symbol}
                          {plan?.plan_price}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {formData.couponVerified ? (
                  <div className="plan-coupon">
                    {/* {console.log("cpeenn" + " " + formData.couponVerified)} */}
                    <div className="coupon-code">
                      <span>
                        {formData.couponcode}
                        {textData?.pay_coupon_applied_text}
                      </span>
                      <span
                        onClick={(e) => cancelVerifyCoupon(e)}
                        className="crossicn"
                      ></span>
                    </div>
                  </div>
                ) : (
                  <div className="plan-coupon">
                    <div
                      className="coupon-code"
                      data-name="openCoupon"
                      value={true}
                      onClick={(e) => handleCoupenClick(e)}
                    >
                      {textData?.pay_coupon_placeholder_text}
                    </div>
                  </div>
                )}
              </div>
              <div className="pay-optinbox">
                <div className="pay-optinbox-inner">
                  <h2 className="pay-title">
                    {textData?.pleng_payment_heading}
                  </h2>

                  {payments
                    ?.sort(
                      (a, b) =>
                        parseFloat(a.display_order) -
                        parseFloat(b.display_order)
                    )
                    .map((data, i) =>
                      data?.payment_id === 20 ? (
                        <div
                          className="pay-mode-box"
                          key={i}
                          onClick={() => {
                            let abaCardurl = `${
                              process.env.REACT_APP_HANGAMA_URL
                            }/billing/pay.php?payment_id=${
                              data?.payment_id
                            }&identity=${identity}&plan_details_id=${
                              data?.plan_details_id
                            }&product_id=${product_id}&type=charge&${urlparams}&lang=${lan}&coupon_code=${
                              formData?.couponcode ? formData?.couponcode : ""
                            }`;
                            // console.log("abalink", abaCardurl);
                            //set ABA card iframe drawer link
                            setAbaCardIframeLink(abaCardurl);
                            setAbaCardPopUpParams({
                              identity: identity,
                              payment_id: data?.payment_id,
                              plan_details_id: data?.plan_details_id,
                              product_id: product_id,
                            });
                            setPushbar((prev) => {
                              return { ...prev, abaPopup: true };
                            });
                          }}
                        >
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="paymode"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/creditDebitIcon.svg"
                                }
                                className="pay-mode-img"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_cc_dc}
                              </span>
                              <br />
                              <span className="payment-cc-dc-icon">
                                {/* <img
                                  alt="visa"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/visa.svg"
                                  }
                                  className="ccdc-icon visa"
                                />
                                <img
                                  alt="mastercard"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/mastercard.png"
                                  }
                                  className="ccdc-icon mastercard"
                                />
                                <img
                                  alt="unionpay"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/unionpay.svg"
                                  }
                                  className="ccdc-icon unionpay"
                                />
                                <img
                                  alt="jcb"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/jcb.svg"
                                  }
                                  className="ccdc-icon jcb"
                                /> */}
                                <img
                                  alt="cartTypesIcon"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/cardTypes.svg"
                                  }
                                  style={{ height: "13px" }}
                                />
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                       : data?.payment_id === 22 ? (
                        <div
                          className="pay-mode-box"
                          key={i}
                          onClick={() =>
                            handleCardPayments(
                              data?.payment_id,
                              data?.plan_details_id
                            )
                          }
                        >
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="smartpay"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/smart-pay.png"
                                }
                                className="pay-mode-img smart-pay"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_smart_pay}
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                       : data?.payment_id === 23 ? (
                        <div
                          className="pay-mode-box"
                          key={i}
                          onClick={() =>
                            handleCardPayments(
                              data?.payment_id,
                              data?.plan_details_id
                            )
                          }
                        >
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="paymodeimg"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/ABA-pay.png"
                                }
                                className="pay-mode-img"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_aba_pay}
                              </span>
                              <br />
                              <span className="aba-subtext">
                                Tap to pay with ABA mobile
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : data?.payment_id === 25 &&
                        plengTestUserIds.includes(identity) ? (
                        <div
                          className="pay-mode-box"
                          key={i}
                          onClick={() =>
                            handleCardPayments(
                              data?.payment_id,
                              data?.plan_details_id
                            )
                          }
                        >
                          <div className="payment-box flex1">
                            <div className="pay-mode-icon-box">
                              <img
                                alt="acledapay"
                                src={
                                  process.env.REACT_APP_ASSETS_URL +
                                  "/mobile/pleng/images/acleda.logo.svg"
                                }
                                className="pay-mode-img smart-pay"
                              />
                            </div>
                            <div className="pay-moffer-box">
                              <span className="paym-name">
                                {textData?.pleng_payment_acleda
                                  ? textData?.pleng_payment_acleda
                                  : "Acleda Pay"}
                              </span>
                            </div>
                            <div className="pay-mnext-arrowbox">
                              <div className="pay-mnext-arrow">
                                <img
                                  alt="nextarrow"
                                  src={
                                    process.env.REACT_APP_ASSETS_URL +
                                    "/mobile/pleng/images/next-arrow.svg"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : data?.payment_id === 11 ? (
                        // <div
                        //   className="pay-mode-box"
                        //   key={i}
                        //   onClick={() =>
                        //     handleCardPayments(
                        //       data?.payment_id,
                        //       data?.plan_details_id
                        //     )
                        //   }
                        // >
                        //   <div className="payment-box flex1">
                        //     {/* <div className="pay-mode-icon-box"> */}
                        //     <img
                        //       alt="googlewallet"
                        //       src={
                        //         process.env.REACT_APP_ASSETS_URL +
                        //         "/mobile/pleng/images/play-store.svg"
                        //       }
                        //       className="pay-mode-img"
                        //     />
                        //     {/* </div> */}
                        //     <div className="pay-moffer-box">
                        //       <span className="paym-name">
                        //         {textData?.pleng_payment_google_wallet
                        //           ? textData?.pleng_payment_google_wallet
                        //           : "Google Wallet"}
                        //       </span>
                        //     </div>
                        //     <div className="pay-mnext-arrowbox">
                        //       <div className="pay-mnext-arrow">
                        //         <img
                        //           alt="nextarrow"
                        //           src={
                        //             process.env.REACT_APP_ASSETS_URL +
                        //             "/mobile/pleng/images/next-arrow.svg"
                        //           }
                        //         />
                        //       </div>
                        //     </div>
                        //   </div>
                        // </div>
                        ""
                      ) : (
                        ""
                      )
                    )}
                  {/* <div class="pay-mode-box open-mb">
						<p class="p-t1">Credit / Debit Cards</p>
						<div id="cc-details-box" style={{display: "block"}}>
							   <form action="#" method="POST">
								  <input type="hidden" name="hiddenpayment_id" id="hiddenpayment_id" value="1"/>
                                  <input type="hidden" name="hiddenplan_details_id" id="hiddenplan_details_id" value="1"/>
								  <div class="ccrow"><input type="text" name="cardHolderName" id="cardHolderName" placeholder="Card Holder Name" class="ccinput" value=""/></div>
								  <div class="ccrow"><input class="ccinput" name="cardNumber" id="cardNumber" placeholder="Card Number" autocomplete="off" type="text" value="" inputmode="numeric"/></div>
								  <div class="ccrow">
									 <div class="cc-left-box"><input id="expiryDate" name="expiryDate" placeholder="Valid Thru (MM/YY)" class="ccinput" type="text" value="" inputmode="numeric"/></div>
									 <div class="cc-right-box"><input maxlength="4" id="cvv" name="cvv" class="ccinput" placeholder="CVV" type="password" value="" inputmode="numeric"/></div>
									 <input type="hidden" name="pg" value=""/>
								  </div>
								  <div class="ccrow">
									 <div></div>
								  </div>
								  <div class="ccrow"><button type="submit" plan_details_id="1" payment_id="1" class="button-blue w-100">Pay Now</button></div>
								  <div class="savesecurepayment"><img src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/lock-icon.svg"} alt="lockicon" class="lockicon"/> Safe &amp; Secure Payment</div>
							    <div class="agree-row"><label><input type="checkbox" name="checkbox" value="value" checked=""/>I hereby agree and authorize Pleng to debit my account and setup standing instruction.</label></div>
							   </form>
							</div>
				            </div> */}

                  {/* <div className="pay-mode-box">
                    <div className="google-play-store-box flex1">
                        <div className="gpsc">
                            <img src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/play-store.svg"} alt="play store" className="gplayimg"/> Google Wallet
                        </div>
                                        <div className="nextarrow pointer"><span className="hungama-coins">0</span><img src={process.env.REACT_APP_ASSETS_URL + "/mobile/pleng/images/next-arrow.svg"} alt="next arrow"/></div>
                                    </div>
                </div>  */}
                  {/* <div class="pay-mode-box">
                            <div class="payment-box flex1">
                                <div class="pay-mode-icon-box">
                                <img src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/play-store.svg"} alt="play store" className="gplayimg"/>
                                </div>
                                <div class="pay-moffer-box">
                                    <span class="paym-name">Google Wallet</span> 
                                    
                                </div>
                                <div class="pay-mnext-arrowbox">
                                    <a href="javascript:void(0)" class="pay-mnext-arrow"><span className="hungama-coins"></span><img alt="nextarrow" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/next-arrow.svg"}/></a>
                                </div>
                            </div>
                        </div> */}

                  {/*walllet
                  <div class="pay-mode-box open-mb">
					<p class="p-t1">Wallet</p>
					
					<div class="other-payment flex1">
						<div class="other-payment-box">
							<a href="javascript:void(0)"><img alt="paymodeimg" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/pipay.png"} class="pay-mode-img pipay-bg"/></a>
							<p class="other-payment-name"><a href="javascript:void(0)">Pipay</a></p>
						</div>
						<div class="pay-mdown-arrow">
							<a href="javascript:void(0)" class="mdown-arrow"><img alt="downarrow" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/down-arrow.svg"}/></a>
					</div>
						
					</div>
				</div>  */}
                  {/* upis other payments <div class="pay-mode-box open-mb">
					<p class="p-t1">Other Payment Methods</p>
					<div class="other-payment flex1">
						<div class="other-payment-box">
							<a href="javascript:void(0)"><img alt="paymode" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/gpay.png"} class="pay-mode-img"/></a>
							<p class="other-payment-name"><a href="javascript:void(0)">Gpay</a></p>
						</div>
						
					</div>
					<div class="pay-mdown-arrow">
							<a href="javascript:void(0)" class="mdown-arrow"><img alt="downarrow" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/down-arrow.svg"}/></a>
					</div>
				</div>  */}

                  {/* coins <div class="pay-mode-box open-mb">
					<div class="hcoins-box flex1">
						<div class="hcoins">
							<img alt="hcoinsimg" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/redeem-points-icon.png"} class="hcoinsimg"/> Redeem Points
						</div>
						<a href="javascript:void(0)" class="nextarrow hcoin-arrow"><span class="hungama-coins">10,000</span> <img alt="nextarrow" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/next-arrow.svg"}/></a>
                        
					</div>
                    <p class="available-coin">Available Pleng Points 27,900</p>
				</div>	 */}
                </div>
              </div>
            </div>
          </div>
          <aside
            data-pushbar-id="bottom"
            className={`pushbar from_bottom ${
              pushbar?.openCoupon ? "opened" : ""
            }`}
          >
            <div className="paddcpbox">
              <div
                className="coupon-close"
                onClick={(e) => {
                  setPushbar((prev) => {
                    return { ...prev, openCoupon: !pushbar?.openCoupon };
                  });
                  // handleClearCoupon(e);
                }}
                data-pushbar-close
              >
                <img
                  alt="close"
                  src={
                    process.env.REACT_APP_ASSETS_URL +
                    "/mobile/pleng/images/close-btn.svg"
                  }
                />
              </div>
              <div className="close_sep"></div>
              <div className="cptitle">{textData?.pay_coupon_popup_text}</div>
              <div className="cpbxcon">
                <div className="cpbxcon-input-box">
                  <input
                    name="couponcode"
                    value={formData.couponcode}
                    onChange={(e) => handleInput(e)}
                    type="text"
                  />
                  {formData.couponError && formData?.couponcode !== "" && (
                    <img
                      alt="close icon"
                      onClick={(e) => handleClearCoupon(e)}
                      src={
                        process.env.REACT_APP_ASSETS_URL +
                        "/mobile/pleng/images/coupon_error_icon.svg"
                      }
                      className="error-closebtn"
                    />
                  )}
                </div>
                <div onClick={verifyCoupon} className="button-blue">
                  {textData?.pay_coupon_apply_text}
                </div>
              </div>
              {formData.couponError && (
                <div className="error-box">
                  {textData?.pay_coupon_error_text}
                </div>
              )}
            </div>
          </aside>
          {/* invalid smartPay number popup start */}
          <aside
            data-pushbar-id="bottom1"
            className={`pushbar from_bottom ht75 ${
              pushbar?.invalidSmartNumber ? "opened" : ""
            }`}
          >
            <div className="pop-up-wrapper">
              <div className="upi-popup-content pay-popup-declined">
                <div className="payment-declined">
                  <img
                    src={
                      process.env.REACT_APP_ASSETS_URL +
                      "/mobile/pleng/images/payment-declined-icon.svg"
                    }
                    className="smart pay payment declined icon"
                  />
                </div>
                <p className="p-txt1">Payment Declined</p>
                <p className="p-upiid r-points">
                  Smart number not detected. Please choose another payment
                  method.
                </p>
                <div className="p-paybtnbox">
                  <a
                    onClick={(e) => {
                      setPushbar((prev) => {
                        return {
                          ...prev,
                          invalidSmartNumber: !pushbar?.invalidSmartNumber,
                        };
                      });
                    }}
                    href="javascript:void(0)"
                    className="button-cancel mr-10"
                    data-pushbar-close
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </aside>
          {/* invalid smartPay number popup end */}

          {/* ABA PG popup start */}
          <aside
            data-pushbar-id="bottom2"
            className={`pushbar from_bottom ht100 ${
              pushbar?.abaPopup ? "opened" : ""
            }`}
          >
            <div className="pop-up-wrapper aba-up-wrapper">
              <div className="aba-popup-content">
                {abaCardIframeLink && (
                  <iframe
                    id="aba-iframe"
                    onLoad={(e) => {
                      //checkRecentOrder();
                    }}
                    style={{ width: "100%", height: "100vw" }}
                    src={abaCardIframeLink}
                  />
                )}
              </div>
            </div>
          </aside>

          {/* ABA PG popup end */}

          {/* 
   upi-gpay popups

   <aside data-pushbar-id="bottom1" class="pushbar from_bottom ht75">
    <div class="pop-up-wrapper">
        <div class="upi-popup-content">
            <div class="p-paymenticon">
                <img alt="payicon" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/upi.svg"} class="p-payicon"/> 
            </div>
            <p class="p-txt1">Pay With</p>
            <p class="p-upiid">9131074348@paytm</p>
            <div class="p-paybtnbox">
                <a href="javascript:void(0)" class="button-cancel mr-10" data-pushbar-close><i class="icon-Remove button-icon"></i>Close</a>
                <a href="javascript:void(0)" class="button-blue button-pay-now" data-pushbar-target="bottom2"><img alt="paynow" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/paynow.svg"} class="button-icon"/>Pay Now</a>
            </div>
            <div class="p-securebox">
                <img alt="lockicon" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/lock-icon.svg"} class="lockicon"/> Safe & Secure Payment
            </div>
        </div>
    </div>    
</aside> */}
          {/* 
upi-gpay popups
  <aside data-pushbar-id="bottom2" class="pushbar from_bottom ht83">
    <div class="pop-up-wrapper">
        <div class="upi-popup-content">
            <div class="p-paymenticon">
                <img src="images/gpay.svg" class="p-payicon"/> 
            </div>
            <p class="p-txt1">Open Google Pay</p>
            <p class="p-verify-status">Please wait. Verifying payment Sataus</p>
            <div class="p-timmer-box">
                04:23
            </div>
            <div class="p-paybtnbox">
                <a href="javascript:void(0)" class="p-cancel-btn" data-pushbar-close>Close</a>
                <a href="javascript:void(0)" class="p-blue-btn">Refresh</a>
            </div>
            <div class="p-securebox">
                <img alt="lockicon" src={process.env.REACT_APP_ASSETS_URL+"/mobile/pleng/images/lock-icon.svg"} class="lockicon"/> Safe & Secure Payment
            </div>
        </div>
    </div>
</aside> */}
        </>
      )}
    </>
  );
};
