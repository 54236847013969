import React from "react";
export function Image({ src, alt, className, style }) {
    return (
        <img src={process.env.REACT_APP_ASSETS_URL + `/web/images/${src}`} alt={alt} style={style} className={className} />
    )
}

export function LI({ src, alt }) {
    return (
        <img src={process.env.REACT_APP_ASSETS_URL + `/web/images/${src}`} alt={alt} />
    )
}

export function TamashaImage({ src, alt, className, style }) {
    return (
        <img src='https://p.hungama.com/bd/assets/images/tamashaa-logo.png' alt={alt} style={style} className={className} />
    )
}

export function VodacomImage({ src, alt, className, style }) {
    return (
        <img src='https://images.hungama.com/c/uploads_hungama/20240821/20240821104857_new_myMuze.png' alt={alt} style={style} className={className} />
    )
}