import React from "react";
import ReactGA from "react-ga";
import { useHistory ,useLocation } from "react-router-dom";
import { Image } from "../subcomponents/Elements";
import {VodacomImage} from "../subcomponents/Elements"
import { amplitudeBtnEvent, ampRabbitMQBtnEvent } from "../../utils";
const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
const useAnalyticsEventTracker = (category = "Payment Init") => {
  const eventTracker = (action = "action init", label = "") => {
    ReactGA.event({ category, action, label });
  };
  return eventTracker;
};

export default function Header({
  plansData,
  updateMultipleFormData,
  amplitudeOnBack,
  hideHeaderStyle
}) {
  let currenturl = useLocation().search;
  const website = new URLSearchParams(currenturl).get("website");
 if (website) {
    // Store the website value in local storage
    localStorage.setItem("website", website);
 }
  if (website === null) {
    localStorage.setItem('website', 'false');
  }
 const app = new URLSearchParams(currenturl).get("app");
 console.log("app",app);
 let history = useHistory();
  const gaEventTracker = useAnalyticsEventTracker("Payment Plan");
  // console.log();
  return (
    <header id="header"  className="hideHeader">
      <div className="header-inner">
        <div className="logo-toggle logo-toggle-desktop">
          {/*<button className="side-menu-btn">
                    <span onClick={(e) => updateMultipleFormData({ "sideBarMinimize": !plansData.formData.sideBarMinimize })} className="icon-Align-song-up-n-down"></span>
                </button>
*/}
          <span
            onClick={(e) => {
              gaEventTracker("back", "back");
              if (amplitudeOnBack) {
                // amplitudeBtnEvent(
                //   amplitudeOnBack?.identity,
                //   amplitudeOnBack?.eventName
                // );
                ampRabbitMQBtnEvent(
                  {
                    identity: amplitudeOnBack?.identity,
                    product_id: amplitudeOnBack?.product_id,
                    platform_id: amplitudeOnBack?.platform_id,
                  },
                  amplitudeOnBack?.eventName
                );
              }
             if(website == 'true' && app == 'mymuze'){
                window.location.href = `${process.env.REACT_APP_VODACOM_WEB_URL}`;
              }

              // history.goBack();
            }}
            className="backicon"
          >
            <Image src="back-arrow.svg" />
          </span>
            {
              !hideHeaderStyle ?
              <h1 className="logo">
            <a href={'https://mymuze.kollywoodhungama.com'}>
              <VodacomImage src={"logo.svg"} alt={"logo"} />
            </a>
          </h1>:null
            }
          
        </div>

        {/* <div className="hdr-right-part">
                <div className="search-box">
                    <div className="search-bar">
                        <input type="text" placeholder="Search" />
                        
                        <Link to="#" className="icon-Search"></Link>

                    </div>
                    <div className="mic-search">
                        <a href="!#">
                            <span className="icon-Voice-Search"></span>
                        </a>
                    </div>
                </div>
                <div className="get-app-premium">
                    <a href="!#">Get App</a>
                    <a href="!#"><span><Image src={"premium-icon.png"} alt={"premium-icon"} /></span>
                        Buy Premium</a>
                </div>
                <div className="notification-profile">
                    <a href="!#"><span className="icon-Language"></span></a>
                    <a href="!#"><span className="icon-Notification"></span></a>
                    <a href="!#" className="profile-box"><span className="profile-icon">
                        <div className="profile-img"><Image src="profile.png" alt="profile" /></div>
                        <span className="coins"><Image src="coins.png" alt="coins" /> <span>563</span></span>
                    </span>
                        <span className="icon-down"></span>
                    </a>
                </div>
            </div>

            */}
      </div>
    </header>
  );
}
