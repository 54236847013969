import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import {
  amplitudeBtnEvent,
  amplitudeCustomEvent,
  ampRabbitMQBtnEvent,
  ampRabbitMQCustomEvent,
  getAbbrivation
} from "../../../utils";

import { Image } from "../../subcomponents/Elements";
import { verifyCouponCode, Capitalize, language } from "../../../utils/services";
import { SUCCESS } from "../../../../constants";
import { langConvrt } from "../../../utils";
export default function Plan({
  plansData,
  plansDataCopy,
  setPlansData,
  updateMultipleFormData,
  applyCouponCode,
  identity,
  product_id,
  platform_id,
  access_token
}) {
  const [applyBtn, setApplyBtn] = useState(false);
  const [showApplyBtn, setShowApplyBtn] = useState(true);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [ContenDescription, setContenDescription] = useState('');
console.log('insides language',plansData);
  let history = useHistory();
  const location = useLocation();
  let currenturl = useLocation().search;
  const TRACKING_ID = `${process.env.REACT_APP_GA_CODE}`; // OUR_TRACKING_ID
  /* react ga code in useEffect */
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(location.pathname);
  }, [TRACKING_ID, location]);

  const fieldData = plansData.formData;
  const planData = plansData.data;

  let planSelected={}
  let contentInfo =''
  function getPlanById(planId) {
    for (const item of planData?.data?.plans) {
        if (item.plan_id == planId) {
            return item;
        }
    }
    return null;
}
  useEffect(() => {
  if(planData?.data?.plans?.length>0){
    planSelected = getPlanById(planData?.data?.plans[0]?.plan_id);
    console.log('============>',plansData)
    // contentInfo =`By pressing Confirm you agree to purchase ${planSelected?.plan_name}, charged at ${planSelected?.original_price}.00 ${planSelected?.plan_currency}  ${planSelected?.duration?.text}`
    contentInfo = `By clicking the CONFIRM button you agree to subscribe to Vodacom Music for ${planSelected?.duration?.text} at R${planSelected?.original_price}`
    setContenDescription(contentInfo)
  }},[planData?.data?.plans?.length])

  const useAnalyticsEventTracker = (category = "Payment Init") => {
    const eventTracker = (action = "action init", label = "label init") => {
      ReactGA.event({ category, action, label });
    };
    return eventTracker;
  };
  const handleClearCoupon = () => {
    //couponcode cancel logic
    // amplitudeBtnEvent(identity, "Btn_Remove_Coupon");
    ampRabbitMQBtnEvent(
      { identity: identity, product_id: product_id, platform_id: platform_id },
      "Btn_Remove_Coupon"
    );

    updateMultipleFormData({ couponCode: "" });
    setShowApplyBtn(true);
    setApplyBtn(false);

    //set original plans
    // console.log("on clear plansData", plansData);
    setPlansData({ type: "plansData", payload: plansDataCopy });
  };
  const handleCouponOnChange = (coupon) => {
    if (coupon !== "") {
      setApplyBtn(true);
      updateMultipleFormData({ couponCode: coupon });
    } else if (coupon === "") {
      updateMultipleFormData({ couponCode: coupon });
      setApplyBtn(false);
    }
  };
  const verifyCoupon = (event) => {
    event.preventDefault();
    if (fieldData.couponCode !== "") {
      // amplitudeBtnEvent(fieldData.identity, "apply_coupon");
      ampRabbitMQBtnEvent(
        {
          identity: fieldData.identity,
          product_id: product_id,
          platform_id: platform_id,
        },
        "apply_coupon"
      );
      event.preventDefault(); // Prevent default submission
      setShowApplyBtn(false);
      gaEventTracker("apply_coupon", "HUNGAMA_" + fieldData.couponCode);
      verifyCouponCode(fieldData.couponCode).then((res) => {
        if (res.status === SUCCESS) {
          applyCouponCode(res?.data);
          if (
            res.data?.status === 2 &&
            res.data?.coupon_details?.type === "free"
          ) {
            // response.is_coupon_valid = true;
            // Patch :: Assign Half_yearly Plan via coupon code and go directly to resp success/fail page
            let obj = {
              couponCode: res.data?.coupon_code,
              status: res.data?.status,
              ...res.data?.coupon_details,
            };
            let { couponCode, payment_id, plan_details_id, plan_id } = obj;

            return (window.location.href = `${
              process.env.REACT_APP_HANGAMA_URL
            }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${plan_id}&coupon_code=${couponCode}&${currenturl?.replace(
              "?",
              ""
            )}`);
          }
        } else {
          // updateMultipleFormData({ validStatus: true });
        }
      });
    }
  };
  const gaEventTracker = useAnalyticsEventTracker("Payment Plan");
  const callupdate = (a) => {
    /*
    amplitudeCustomEvent(fieldData.identity, "npay_click_planselect", {
      npay_currency: a.plan_currency,
      npay_planid: a.plan_id,
      npay_planname: a.plan_name,
      npay_plan_validity:
        a?.plan_duration?.duration + " " + a.plan_duration?.text,
      npay_planvalue: a.plan_price,
    });
    */

    ampRabbitMQCustomEvent(
      {
        identity: fieldData.identity,
        npay_currency: a.plan_currency,
        npay_planid: a.plan_id,
        npay_planname: a.plan_name,
        npay_plan_validity:
          a?.plan_duration?.duration + " " + a.plan_duration?.text,
        npay_planvalue: a.plan_price,
        product_id: product_id,
        platform_id: platform_id,
      },
      "npay_click_planselect"
    );

    // useEffect(() => {
      if(planData?.data?.plans?.length>0){
        planSelected = getPlanById(a.plan_id);
        // contentInfo =`By pressing Confirm you agree to purchase ${planSelected?.plan_name}, charged at ${planSelected?.original_price} ${planSelected?.plan_currency}  ${planSelected?.duration?.text}`
            contentInfo = `By clicking the CONFIRM button you agree to subscribe to Vodacom Music for ${planSelected?.duration?.text} at R${planSelected?.original_price}`
        console.log('============>',plansData?.language.confirm_subscription_subtext)
        // contentInfo = `${plansData?.language.confirm_subscription_subtext}`
        setContenDescription(contentInfo)
      }
    // },[planData.data.plans.length])
    document.getElementById("x_plan_name").value = a.plan_name;
    document.getElementById("x_plan_price").value = a.plan_price;
    gaEventTracker("pick_plan", a.plan_name);
  };
  // useEffect(() => {
  //   // console.log('fieldData',fieldData.plan_id);
  //   const headers = {
  //     Accept: "application/json",
  //     "Content-Type": "application/json;charset=UTF-8",
  //     "x-api-key": process.env.REACT_APP_X_API_KEY,
  //     "Authorization":access_token
  //   };

  //   const body = {
  //     "redirectionUrl": "https://mymuze-sso.kollywoodhungama.com/api/v1/redirect-uri", 
  //     "errorRedirectionUrl": "https://mymuze-sso.kollywoodhungama.com/api/v1/redirect-uri", 
  //     "lang": "EN", 
  //     "typeId": fieldData.plan_id 
  //   };

  //   axios({
  //     method: "POST",
  //     url: `${process.env.REACT_APP_SUBSCRIPTION_VODACOM}`,
  //     headers: headers,
  //     data: body,
  //   })
  //     .then((res) => {
  //       console.log('=========>',res.data.optionList[0].redirectionUrl);
  //       localStorage.setItem('redirect_url',res.data.optionList[0].redirectionUrl);
  //     })
  //     .catch((err) => {
  //       console.log("'=========>>>>>'",err);
  //     });
  // }, []);


    
const closeConfirmationPopup =() =>{
  setShowConfirmationPopup(false);

}

const openConfirmation=()=>{
  setShowConfirmationPopup(true);
}


const redirectToExternalSite = () => {
  window.open("https://mymuze.kollywoodhungama.com/conditions/", "_blank");
}

  
  const buyGold2= (event) => {
    
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      "x-api-key": process.env.REACT_APP_X_API_KEY,
      "Authorization":access_token
    };
    const body = {
      "redirectionUrl": "https://pay-react.kollywoodhungama.com/vodacom_success", 
      "errorRedirectionUrl": "https://pay-react.kollywoodhungama.com/vodacom_fail", 
      "lang": "EN", 
      "typeId": fieldData.plan_id 
    };
    console.log('plan_id',fieldData);
    axios({
      method: "POST",
      url: `${process.env.REACT_APP_SUBSCRIPTION_VODACOM}`,
      headers: headers,
      data: body,
    })
      .then((res) => {
        console.log('=========>',res.data.optionList[0].redirectionUrl);
        // localStorage.setItem('redirect_url',res.data.optionList[0].redirectionUrl);
        window.location.href = res.data.optionList[0].redirectionUrl
      })
      .catch((err) => {
        console.log("'=========>>>>>'",err);
      });
  }
  const buyGold = (event) => {
    event.preventDefault(); // Prevent default submission
    // amplitudeBtnEvent(fieldData.identity, "Btn_BottomFix_Buy Now");
    ampRabbitMQBtnEvent(
      {
        identity: fieldData.identity,
        product_id: product_id,
        platform_id: platform_id,
      },
      "Btn_BottomFix_Buy Now"
    );

    var planId = [fieldData.plan_id];
    var filteredArray = plansData.data["plans"].filter(function (itm) {
      return planId.indexOf(itm.plan_id) > -1;
    });
    //console.log('buy_hungama_gold',filteredArray[0].plan_name+'_'+filteredArray[0].plan_price);
    gaEventTracker(
      "buy_hungama_gold",
      filteredArray[0].plan_name + "_" + filteredArray[0].plan_price
    );
    if (fieldData.plan_id !== "") {
      if (fieldData.plan_id === 37) {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set("plan_type", "event");
        currenturl = searchParams.toString();
      }

      //Free coupon
      // console.log("freeCouponData:", fieldData?.freeCouponData);
      if (
        fieldData?.freeCouponData?.status === 1 &&
        fieldData?.freeCouponData?.coupon_details?.type === "free"
      ) {
        let obj = {
          couponCode: fieldData?.freeCouponData?.coupon_code,
          status: fieldData?.freeCouponData?.status,
          ...fieldData?.freeCouponData?.coupon_details,
        };
        let { couponCode, payment_id, plan_details_id, plan_id } = obj;
        return (window.location.href = `${
          process.env.REACT_APP_HANGAMA_URL
        }/billing/pay.php?payment_id=13&plan_details_id=${plan_details_id}&type=charge&plan_id=${plan_id}&coupon_code=${couponCode}&${currenturl.replace(
          "?",
          ""
        )}`);
      }

      history.push(
        `/payment?plan_id=${fieldData.plan_id}&couponcode=${
          fieldData.couponCode
        }&${currenturl.replace("?", "")}`
      );
    } else {
      alert("please select a plan");
    }
  };

  // console.log("fieldData::", fieldData);

  return (
    <>
    <div className="planrightsec planrightsecSecondary">
      {/* <div className="selplantxt" style={{margin:"20px"}}>{plansData?.language?.plan_heading_text}</div> */}
      <div className="clearfix planht">
        {planData.data?.plans?.map((x, i) => {
          return (
            <div
              className={`bxplanSecondaryDesktop ${x?.showActive === true ? "active-plan" : ""}
              ${x?.showActive === false ? "in-active-plan" : ""}`}
              key={i}
            >
              {/* Free Trial */}
              {x.is_recommended && (
                <>
                  <div className="rcomm" style={{ display: "none" }}>
                    {plansData?.language?.pay_recommended_text}
                    {/* {plansData?.language?.pay_recommended_text} */}
                  </div>
                  {/* <div>
                    <img
                      className="gifimg"
                      alt="pay-gif"
                      // src={
                      //   process.env.REACT_APP_ASSETS_URL +
                      //   "/web/images/holi_tag.svg"
                      // }
                      src ={"https://images1.hungama.com/p/https://images.hungama.com/c/uploads_hungama/20240430/20240430071525_Summer_sale.png"}
                    />
                  </div> */}
                </>
              )}
              {x.freeTrialPlanRecText && (
                <div className="rcomm">
                  {plansData?.language?.pay_trialoffer_text}
                </div>
              )}
              <div className="paddpan">
                <p className="typeplan typeplanSecondary">
                  {(x?.plan_id === 80150012 || x?.plan_id === 80150017) ? plansData?.language?.free_trial_1:(x?.plan_id === 80150018 ) ?  plansData?.language?.free_trial_2:''}
                </p>

                {x.discountedPrice ? (
                  <div className="ruppetxt ruppetxt-secondary-desktop">
                    <span>{`${x.plan_currency_symbol} ${x.plan_price}`}</span>
                    {`${x.plan_currency_symbol} ${x.discountedPrice}`}
                    <span className="plnval">
                      {langConvrt(
                        Capitalize(x.plan_name),
                        plansData?.defaultlang,
                        plansData?.language
                      )}
                    </span>
                  </div>
                ) : //PRICE PLAN CHANGE
                x?.original_price > x?.plan_price ? (
                  <div className="ruppetxt ruppetxt-secondary-desktop">
                    <span>{`${x.plan_currency_symbol} ${x.original_price}`}</span>{" "}
                    {`${x.plan_currency_symbol} ${x.plan_price}`}
                    <span className="plnval">
                      {langConvrt(
                        Capitalize(x.plan_name),
                        plansData?.defaultlang,
                        plansData?.language
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="ruppetxt ruppetxt-secondary-desktop">
                    {`${x.plan_currency_symbol} ${x.plan_price}  ${getAbbrivation(x)}`}
                    <span className="plnval">
                      {langConvrt(
                        Capitalize(x.plan_name),
                        plansData?.defaultlang,
                        plansData?.language
                      )}
                    </span>
                  </div>
                )}
              </div>
              <label className="containern">
                {x?.is_recommended ? (
                  <input
                    type="radio"
                    name="radio"
                    onClick={(e) => {
                      updateMultipleFormData({
                        plan_id: x.plan_id,
                        plan_name: x.plan_name,
                        plan_price: x.plan_price,
                        plan_currency: x.plan_currency,
                        plan_duration: x.duration,
                      });
                      callupdate({
                        plan_id: x.plan_id,
                        plan_name: x.plan_name,
                        plan_price: x.plan_price,
                        plan_currency: x.plan_currency,
                        plan_duration: x.duration,
                      });
                    }}
                    defaultChecked="checked"
                  />
                ) : (
                  <input
                    type="radio"
                    name="radio"
                    onClick={(e) => {
                      updateMultipleFormData({
                        plan_id: x.plan_id,
                        plan_name: x.plan_name,
                        plan_price: x.plan_price,
                        plan_currency: x.plan_currency,
                        plan_duration: x.duration,
                      });
                      callupdate({
                        plan_id: x.plan_id,
                        plan_name: x.plan_name,
                        plan_price: x.plan_price,
                        plan_currency: x.plan_currency,
                        plan_duration: x.duration,
                      });
                    }}
                  />
                )}
                <span className="checkmark checkmark-secondary-desktop">
                <p className="planname-desktop">
                  {langConvrt(
                    x.plan_name,
                    plansData?.defaultlang,
                    plansData?.language
                  )}
                </p>
                </span>
              </label>
            </div>
          );
        })}
      </div>

      {/* <form onSubmit={(e) => verifyCoupon(e)}>
        <div
          className={`cpbxcon ${
            fieldData?.couponValidStatus === true && showApplyBtn === false
              ? "coupon-success-border"
              : fieldData?.couponValidStatus === false &&
                fieldData?.couponCode !== "" &&
                showApplyBtn === false
              ? "coupon-error-border"
              : ""
          }`}
        >
          <input
            type="text"
            value={fieldData?.couponCode}
            placeholder={plansData?.language?.pay_coupon_placeholder}
            onChange={(e) => handleCouponOnChange(e.target.value)}
          />
          {showApplyBtn === false ? (
            <img
              alt="close icon"
              src={
                process.env.REACT_APP_ASSETS_URL +
                "/web/images/coupon-error-close.svg"
              }
              class="error-closebtn"
              onClick={handleClearCoupon}
            />
          ) : (
            <button
              style={{ opacity: applyBtn === true ? 1 : "" }}
              className="apply_btn cpbtn"
              type="submit"
            >
              {plansData?.language?.pay_coupon_apply_text}
            </button>
          )}
          {fieldData.couponValidStatus === false &&
            fieldData?.couponCode !== "" &&
            fieldData?.isCouponExpired === false &&
            showApplyBtn === false && (
              <p class="coupon-error-msg error-txt">
                {plansData?.language?.pay_coupon_invalid_text}
              </p>
            )}
          {fieldData.couponValidStatus === true &&
            fieldData?.couponCode !== "" &&
            fieldData?.isCouponExpired === false &&
            showApplyBtn === false && (
              <p class="coupon-success-msg error-txt">
                {plansData?.language?.pay_coupon_valid_text}
              </p>
            )}
          {fieldData.couponValidStatus === false &&
            fieldData?.isCouponExpired === true &&
            fieldData?.couponCode !== "" &&
            showApplyBtn === false && (
              <p class="coupon-error-msg error-txt">
                {plansData?.language?.pay_coupon_expired_text}
              </p>
            )}
        </div>
      </form> */}

      <div className="cpbtnsb">
        <input type="hidden" id="x_plan_name" />
        <input type="hidden" id="x_plan_price" />
        {/* <a href="!#" onClick={(e) => gaEventTracker(fieldData.plan_name)&& buyGold(e)} className="goldbtn"> */}
        <a style={{cursor: 'pointer'}} onClick={(e) => openConfirmation(e)} className="vodagoldbtn">
          <Image src="goldimg.svg" alt="golimg" />
          {plansData?.language?.cta_button_subscribe}
        </a>
        {/*<a href="!#" className="asubbtn">
                Already have subcription
    </a>*/}
      </div>
      <p style={{fontSize: '1rem',textAlign:"center"}}>
        <span style={{color: 'white'}}>  {plansData?.language?.renew_string}</span>
      </p>
      <p style={{fontSize: '1rem',textAlign:"center"}}>
        <span > <a target="_blank" href={`${process.env.REACT_APP_VODACOM_WEB_URL}/terms-conditions/`} style={{color: '#E20101'}}>{plansData?.language?.footer_terms_cond}</a></span>
      </p>
      

        {/* <div
        className="pay-popup-wrap"
        id="phonepe-pop"
        style={{
          display: showConfirmationPopup ? "block" : "none",
        }}
      > */}
       
      {/* </div> */}
    </div>
    <div className="paypopup-box"
            style={{
              display: showConfirmationPopup ? "block" : "none",
               top:'55%',
               zIndex:'10',
               position:'fixed'
            }}
        >
          <span
            // onClick={(e) =>
            //   updateMultipleFormData({
            //     reedemCoinsFailed: !paymentsData?.formData?.reedemCoinsFailed,
            //   })
            // }
            onClick={()=>closeConfirmationPopup()}
            className="pop-close"
            id="pop-closebtn"
          >
            <Image src="close-btn.png" alt="" />
          </span>
          <div className="paypop-content" style={{gap:"24px",display:"flex", flexDirection:"column"}}>
            <div className="pop-pay-icon-box">
            <p className="pop-pay-txt" style={{ fontSize: "1rem" }}>
                {plansData?.language.confirm_subscription}
              </p>
              <p className="pop-secure">
                {/* {paymentsData?.language?.pay_popup_coin_insufficient} */}
                {/* By pressing confirm you agree to purcahse vodacom Music Premium Daily, Charged at 5.00 ZAR per day */}
                {ContenDescription}
              </p>
              <div style={{display:"flex",flexDirection:"column", width:"100%",marginTop:"24px",display:"flex",
                justifyContent:"center",alignItems:"center",gap:"24px"
              }} >
              <span className="pop-pay-txt1" style={{background:"#7DC78A",borderRadius:"30px",paddingTop: "10px",paddingBottom:"10px",paddingRight:"20px",paddingLeft:"20px",width:'350px',fontSize:'14px'}}  onClick={(e)=>{buyGold2(); closeConfirmationPopup()}}>{plansData?.language.subscription_confirm_CTA}</span>
              <span className="pop-pay-txt1" style={{textDecoration:"underline"}} onClick={(e)=>{closeConfirmationPopup();redirectToExternalSite()}}> Terms and Conditions</span>

              </div>
            </div>
          </div>
        </div>
    </>
    
    
  );
}
